import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';

const queryClient = new QueryClient();

const removeConsoleLogsForNonDevEnvironment = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    const overrideConsoleBehaviour = () => {};
    console.log = overrideConsoleBehaviour;
    console.warn = overrideConsoleBehaviour;
    console.error = overrideConsoleBehaviour;
  }
};

removeConsoleLogsForNonDevEnvironment();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
