/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { UnauthorisedOnlyRoute } from './components';
import * as enums from '../../utils/constants';
import './app.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('../../components/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('../Login'));

const App = () => {
  return (
    <BrowserRouter basename={`${enums.BASE_NAME}`}>
      <React.Suspense fallback={loading()}>
        <Switch>
          <UnauthorisedOnlyRoute
            exact
            path={`${enums.LOGIN_PATH}`}
            name="Login Page"
            render={(props) => <Login {...props} />}
          />

          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
