import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { checkAuthentication } from '../utils';

import { LOGIN_PATH } from '../../../utils/constants';

const PrivateRoute = (props) => {
  const { render, exact, path, name } = props;
  const isUserAuthenticated = checkAuthentication();

  if (isUserAuthenticated) {
    return <Route exact={exact} path={path} name={name} render={render} />;
  }

  return <Redirect to={LOGIN_PATH} />;
};

PrivateRoute.propTypes = {
  render: PropTypes.func.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

PrivateRoute.defaultProps = {
  exact: false,
};

export default PrivateRoute;
