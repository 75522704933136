export const BASE_NAME = '/admin';

export const ROOT_PATH = '/dashboard';
export const LOGIN_PATH = '/login';

export const ACCOUNT_CAPTURE_TYPES_PATH = '/accountCaptureTypes';
export const ACCOUNTS_PATH = '/accounts';
export const ADMIN_USERS_PATH = '/adminUsers';
export const ASSETS_PATH = '/assets';
export const CAPTURES_PATH = '/captures';
export const CAPTURE_TYPES_PATH = '/captureTypes';
export const DEFECT_TYPES_PATH = '/defectTypes';
export const ELEMENTS_PATH = '/elements';
export const REPORTS_PATH = '/reports';
export const SHARES_PATH = '/shares';
export const SITES_PATH = '/sites';
export const STORAGES_PATH = '/storages';
export const TASK_TYPES_PATH = '/taskTypes';
export const TILESET_3DS_PATH = '/tileset3ds';
export const USERS_PATH = '/users';
export const ZIP_REQUESTS_PATH = '/zipRequests';

export const ROOT_TYPE = 'Dashboard';
export const ACCOUNT_CAPTURE_TYPES_TYPE = 'AccountCaptureTypes';
export const ACCOUNTS_TYPE = 'Accounts';
export const ADMIN_USERS_TYPE = 'AdminUsers';
export const ASSETS_TYPE = 'Assets';
export const CAPTURES_TYPE = 'Captures';
export const CAPTURE_TYPES_TYPE = 'CaptureTypes';
export const DEFECT_TYPES_TYPE = 'DefectTypes';
export const ELEMENTS_TYPE = 'Elements';
export const REPORTS_TYPE = 'Reports';
export const SHARES_TYPE = 'Shares';
export const SITES_TYPE = 'Sites';
export const STORAGES_TYPE = 'Storages';
export const TASK_TYPES_TYPE = 'TaskTypes';
export const TILESET_3DS_TYPE = 'Tileset3ds';
export const USERS_TYPE = 'Users';
export const ZIP_REQUESTS_TYPE = 'ZipRequests';

export const SUCCESS_MESSAGE = 'Success';
export const REJECTED_MESSAGE = 'Rejected';

export const BOOLEAN_TYPE = 'boolean';
export const STRING_TYPE = 'string';
export const OPTION_TYPE = 'option';

export const COLUMN_STRING_TYPE = 'string';
export const COLUMN_BOOLEAN_TYPE = 'boolean';
export const COLUMN_LINK_TYPE = 'link';

export const INPUT_TEXT_TYPE = 'text';
export const INPUT_EMAIL_TYPE = 'email';
export const INPUT_PASSWORD_TYPE = 'password';
export const INPUT_SINGLE_SELECT_TYPE = 'singleSelect';
export const INPUT_MULTI_SELECT_TYPE = 'multiSelect';
export const INPUT_SINGLE_CHECKBOX_TYPE = 'singleCheckbox';

export const ACCESS_TOKEN_NAME = 'skand-platform-admin-dashboard-access-token';

export const CAPTURE_FORMAT_TYPE = {
  MESH_3D: 'MESH_3D',
  POINT_CLOUD: 'POINT_CLOUD',
  ORTHO_2D: 'ORTHO_2D',
  BIM_CAD_MODEL: 'BIM_CAD_MODEL',
  DOCUMENT: 'DOCUMENT',
};

export const CAPTURE_RENDERER_TYPE = {
  TILESET_3D: 'TILESET_3D',
  KML: 'KML',
  IMAGERY: 'IMAGERY',
};

export const CAPTURE_STATUS = {
  PROCESSING: 'PROCESSING',
  UNPUBLISHED: 'UNPUBLISHED',
  PUBLISHED: 'PUBLISHED',
};

export const EVENT_STATUS = {
  NO_EVENT: 'NO_EVENT',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
};

export const INGEST_IMAGE_TYPE = {
  UNKNOWN: 'UNKNOWN',
  HIGH_PASS: 'HIGH_PASS',
  LOW_PASS: 'LOW_PASS',
  OBLIQUE: 'OBLIQUE',
  VERTICAL: 'VERTICAL',
  GRID: 'GRID',
};

export const INGEST_USER_TOUCH = {
  USER_NO_ACTION: 'USER_NO_ACTION',
  USER_EXCLUDED: 'USER_EXCLUDED',
  USER_INCLUDED: 'USER_INCLUDED',
};

export const INGEST_CAPTURE_STATUS = {
  CREATED: 'CREATED',
  SERVICE_BOOKED: 'SERVICE_BOOKED',
  READY_FOR_UPLOAD: 'READY_FOR_UPLOAD',
  VALIDATING: 'VALIDATING',
  VALIDATED: 'VALIDATED',
  SUBMITTED: 'SUBMITTED',
  PUBLISHING: 'PUBLISHING',
  PUBLISHED: 'PUBLISHED',
};

export const CORE_IMAGE_TYPE = {
  UNKNOWN_TYPE: 'UNKNOWN_TYPE',
  LOW_PASS: 'LOW_PASS',
  VERTICAL: 'VERTICAL',
  GRID: 'GRID',
  OBLIQUE: 'OBLIQUE',
  INTERNAL: 'INTERNAL',
};

export const CORE_IMAGE_FORMAT = {
  UNKNOWN_FORMAT: 'UNKNOWN_FORMAT',
  PHOTO: 'PHOTO',
  THERMAL: 'THERMAL',
  ASSET_FUTURES: 'ASSET_FUTURES',
  WIND_TURBINE: 'WIND_TURBINE',
};

export const CORE_IMAGE_STATUS = {
  CREATED: 'CREATED',
  FLAGGED: 'FLAGGED',
  TAGGED: 'TAGGED',
};

export const CORE_JOB_STATUS = {
  CREATED: 'CREATED',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  ALIGNED: 'ALIGNED',
  TAGGED: 'TAGGED',
  APPROVED: 'APPROVED',
  CLOSED: 'CLOSED',
};

export const CORE_USER_ROLE = {
  TAGGER: 'TAGGER',
  BATCH_MANAGER: 'BATCH_MANAGER',
  ADMIN: 'ADMIN',
};

export const INGEST_IMAGE_TYPE_MAPPING = {
  0: INGEST_IMAGE_TYPE.UNKNOWN,
  10: INGEST_IMAGE_TYPE.HIGH_PASS,
  20: INGEST_IMAGE_TYPE.LOW_PASS,
  30: INGEST_IMAGE_TYPE.OBLIQUE,
  40: INGEST_IMAGE_TYPE.VERTICAL,
  50: INGEST_IMAGE_TYPE.GRID,
};

export const INGEST_USER_TOUCH_MAPPING = {
  0: INGEST_USER_TOUCH.USER_NO_ACTION,
  10: INGEST_USER_TOUCH.USER_EXCLUDED,
  20: INGEST_USER_TOUCH.USER_INCLUDED,
};

export const INGEST_CAPTURE_STATUS_MAPPING = {
  0: INGEST_CAPTURE_STATUS.CREATED,
  2: INGEST_CAPTURE_STATUS.SERVICE_BOOKED,
  3: INGEST_CAPTURE_STATUS.READY_FOR_UPLOAD,
  5: INGEST_CAPTURE_STATUS.VALIDATING,
  10: INGEST_CAPTURE_STATUS.VALIDATED,
  20: INGEST_CAPTURE_STATUS.SUBMITTED,
  25: INGEST_CAPTURE_STATUS.PUBLISHING,
  30: INGEST_CAPTURE_STATUS.PUBLISHED,
};

export const CORE_IMAGE_TYPE_MAPPING = {
  0: CORE_IMAGE_TYPE.UNKNOWN_TYPE,
  10: CORE_IMAGE_TYPE.LOW_PASS,
  20: CORE_IMAGE_TYPE.VERTICAL,
  30: CORE_IMAGE_TYPE.GRID,
  40: CORE_IMAGE_TYPE.OBLIQUE,
  50: CORE_IMAGE_TYPE.INTERNAL,
};

export const CORE_IMAGE_FORMAT_MAPPING = {
  0: CORE_IMAGE_FORMAT.UNKNOWN_FORMAT,
  10: CORE_IMAGE_FORMAT.PHOTO,
  20: CORE_IMAGE_FORMAT.THERMAL,
  30: CORE_IMAGE_FORMAT.ASSET_FUTURES,
  40: CORE_IMAGE_FORMAT.WIND_TURBINE,
};

export const CORE_IMAGE_STATUS_MAPPING = {
  0: CORE_IMAGE_STATUS.CREATED,
  1: CORE_IMAGE_STATUS.FLAGGED,
  2: CORE_IMAGE_STATUS.TAGGED,
};

export const CORE_JOB_STATUS_MAPPING = {
  0: CORE_JOB_STATUS.CREATED,
  10: CORE_JOB_STATUS.UPLOADING,
  20: CORE_JOB_STATUS.UPLOADED,
  30: CORE_JOB_STATUS.ALIGNED,
  40: CORE_JOB_STATUS.TAGGED,
  50: CORE_JOB_STATUS.APPROVED,
  70: CORE_JOB_STATUS.CLOSED,
};

export const CORE_USER_ROLE_MAPPING = {
  0: CORE_USER_ROLE.TAGGER,
  1: CORE_USER_ROLE.BATCH_MANAGER,
  2: CORE_USER_ROLE.ADMIN,
};

export const STORAGE_USAGE_TYPES = {
  SUPPORTING_FILE: 'SUPPORTING_FILE',
  DATASET_FILE: 'DATASET_FILE',
};
