import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { checkAuthentication } from '../utils';

import { ROOT_PATH } from '../../../utils/constants';

const UnauthorisedOnlyRoute = (props) => {
  const { render, exact, path } = props;
  const isUserAuthenticated = checkAuthentication();

  if (!isUserAuthenticated) {
    return <Route exact={exact} path={path} render={render} />;
  }

  return <Redirect to={ROOT_PATH} />;
};

UnauthorisedOnlyRoute.propTypes = {
  render: PropTypes.func.isRequired,
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
};

export default UnauthorisedOnlyRoute;
